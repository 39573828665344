<template>
  <div>
    <Header />
    <v-app class="industrial-design-wrapped">
      <ScrollToTop />
      <div class="industrial-design-header">
        <small>{{ $t("expertise_uppercase") }} / {{ $t("services") }}</small>
        <h1 class="head-title">{{ $t("industrial_design") }}</h1>
      </div>
      <v-container class="industrial-design-body">
        <p>
          {{ $t("design_line_1") }}
        </p>
        <span><strong>{{ $t("design_bold") }}</strong></span>
        <div class="d-flex align-center mb-1">
          <p class="ma-0">
            {{ $t("design_line_2") }}
          </p>
        </div>
        <br />
        <div class="d-flex align-center justify-content-center mt-5 mb-1">
          <div class="ma-0 text-center card pa-2">
            <strong>{{ $t("design_bold_2") }}</strong>
            <img src="~@/assets/alur-pendaftaran-desain-industri.png" />
          </div>
        </div>
      </v-container>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2.vue";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "../../components/ScrollToTop.vue";

export default {
  name: "IndustrialDesign",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
@font-face {
  font-family: Steelfish;
  src: url("~@/assets/fonts/steelfish-rg.otf");
}

.industrial-design-wrapped {
  margin-top: 6.5em;
}

.industrial-design-header {
  background: url("~@/assets/industrial-design-bg.jpg") no-repeat;
  height: 15em;
  padding: 3em;
  background-color: #212121b3;
  color: #ffffff;
  background-blend-mode: soft-light;
  text-align: center;
  background-position-y: 55%;
}

h1 {
  font-family: "Steelfish", sans-serif;
  font-size: 7em;
}

.fa-angle-right {
  color: #8c3d40;
}

.industrial-design-body {
  padding: 3em;
  max-width: 75%;
  line-height: 2em;
}

@media screen and (max-width: 480px) {
  .head-title {
    font-size: 4em;
  }

  .industrial-design-body {
    max-width: 100%;
  }
}
</style>
